html {
  background-position: top left;
  background-attachment: fixed;
  background: rgb(244,236,255);
  background: linear-gradient(90deg, rgba(244,236,255,1) 0%, rgba(244,244,255,1) 35%, rgba(244,253,255,1) 100%);
  font-size: 10px;
}

html, body {
  font-family: Sora, Arial, sans-serif;
  text-shadow: 0 1px 1px #fff;
  color: #346;
}

body {
  font-size: 1.6rem;
}

select, textarea {
  font-family: Sora, Arial, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: Pacifico;
  color: #2255FF;
  font-size: 60px;
  margin: 1rem auto;
  text-shadow: 0 1px 1px #dbcbff;
  font-weight: 200;
}

h1 a {
  text-decoration: none;
  transition: 0.5s;
}

h1 a:hover {
  color: #986bff;
}

h1, h2, h3, h4, h5 {
  color: #358;
}

h2 {
  text-align: center;
  border-bottom: 1px solid #358;
  padding: 2rem;
}

h2 .subtitle {
  display: block;
  font-size: 70%;
  font-weight: 400;
}

hr {
  border-color: #986bff;
  border-top: 1px solid #fff;
  border-left: none;
  border-right: none;
  height: 0;
}

a { color: #2233DD; }

strong { color: #222; }

code {
  background: #fff;
  display: inline-block;
  padding: 3px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  max-width: 90%;
  width: 900px;
  padding: 2rem;
  margin: 0 auto;
  text-align: left;
}

dl {
  border: 1px solid #2233DD;
  padding: 3rem;
  font-size: 2rem;
  line-height: 3rem;
  border-radius: 1rem;
  background: rgba(255,255,255,0.9);
  text-align: left;
  box-shadow: 0 1px 2px #2233DD;
}

dt {
  color: #2233DD;
  font-weight: 600;
  border-bottom: 1px solid #2233DD;
}

dd {
  margin: 1rem 0;
  padding: 0;
}

ul.lemons li {
  list-style: none;
  margin: 2rem 0;
}

.fa-lemon {
  color: #ffa200;
  text-shadow: 0 0 1px #fff;
  font-size: 2rem;
  display: inline-block;
  margin-right: 1rem;
}

footer {
  padding: 3rem;
}
