.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

main {
  min-height: 50vh;
}

nav a {
  display: inline-block;
  background: rgba(255,255,255,0.5);
  border: 1px solid rgba(0,0,0,0);
  border-radius: 3rem;
  margin: 1rem;
  padding: 1rem 2rem;
  text-decoration: none;
  font-weight: 600;
  font-size: 2rem;
  box-shadow: 0 1px 1px #fff;
  transition: 0.2s;
}

nav a.active {
  color: #333;
  border: 1px solid rgba(0,0,100,.5);
}

nav a span {
  font-weight: bold;
  text-decoration: underline;
}

nav a:hover {
  box-shadow: 0 1px 2px #61dafb;
}
